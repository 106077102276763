.stick {
    position: fixed;
    top: 10px;
}

.icon-button-holder {
    width: 60px;
    height: 60px;
}

.settings-panel {
    width: 420px;
}
