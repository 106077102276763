.top-nav-bar {
  min-height: 40px; }
  .top-nav-bar a:hover {
    text-decoration: none !important; }
  .top-nav-bar .top-navigation-nav-link {
    position: relative; }
    .top-nav-bar .top-navigation-nav-link .nav-link + .nav-link-border-bottom {
      width: 0%;
      border-bottom: 3px solid transparent;
      transition: all 0.3s; }
    .top-nav-bar .top-navigation-nav-link .nav-link.active + .nav-link-border-bottom {
      position: absolute;
      bottom: -0.5rem;
      width: 90%;
      border-bottom: 3px solid var(--primary);
      transition: all 0.3s; }

.side-navigation {
  min-width: 6rem;
  max-width: 6rem;
  color: #fff;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  transition: all 0.3s; }

.side-navigation-nav-link {
  width: 72px;
  height: 72px;
  color: #212121;
  text-align: center;
  background: #00000000;
  /* Transparent Blue for backgrounds */ }
  .side-navigation-nav-link.active {
    color: var(--primary);
    text-align: center;
    background: #007bff1a;
    /* Transparent Blue for backgrounds */
    border: 3px solid #0099ff09; }

/* Font Sizing  */
.fs-50 {
  font-size: 7px; }
