.card{
    background:#ffffff !important;
    /* background-image:  linear-gradient(90deg, #ffffff 20%, #f8f8f8 100%) !important; */
    border-radius: 21px !important;
    box-shadow:  0 .5rem 2rem -17px rgba(0,0,0,.11) !important;
    border: 0 !important;
}



