.editor {
  width: 600px;
  max-width: 600px;
  min-width: 600px;
}

.height-inherit {
  height: inherit;
}

.dropzone {
  width: 100%;
  height: 200px;
  border: 1px dashed #000;
  position: relative;
}

.dropzone-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2.5em 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
}

.editor-input-box {
  height: 36rem;
  overflow-y: scroll;
  margin-top: 1rem;
  padding: 1rem;
}

.draftJsEmojiPlugin__emojiSelect__34S1B {
  height: 108px !important;
}


.custom-bg{
  background-color: #efefef;
}